import React from 'react';
import {Logo as DefaultLogo} from '@digitaltransformation/qone.saas.branding';
import {Box} from 'rebass'

/**
 * @type { fill: string | any }
 */
interface ILogoProps {
    fill: string | any
}

/**
 * Component overrides { Logo }
 * Size props range: 25-35px (recommended)
 * @typeof fill toggles state of svg mask inversion
 * @interface ILogoProps
 */
class Logo extends React.Component<ILogoProps> {
    public render() {
        return (
            <Box
                style={{
                    width: '75%',
                    transform: 'scale(1.1)',
                    transformOrigin: 'bottom left',
                }}
            >
                <DefaultLogo
                    fill={this.props.fill ?? 'black'}
                />
            </Box>
        )
    }
}

export default Logo;