import * as React from 'react';
import {useColorMode} from "theme-ui";
import {MainCover} from "@digitaltransformation/qone.pages.layout";
import NavigationHeader from '@components/Navigation/Navigation.Header';

import {Box} from "rebass";
import {graphql, StaticQuery} from "gatsby";

const MediaQuery = graphql`
    query GetCoverMediaAssets {
        imageSharp(fluid: { originalName: {eq: "quantone-cover-media-static.png"}}) {
            id
            fluid(maxWidth: 1600) {
                originalName
                srcWebp
            }
        }
    }
`

interface IMainCoverProps {
    style?: React.CSSProperties,
    fill: string,
    children?: any,
}

/**
 * Implementation of MainCover media container
 * Renders pass-through children components
 */
class MainCoverImpl extends React.Component<IMainCoverProps> {
    public render() {
        const darkModeFill = this.props.fill == "#000" ? "#fff" : "#000";

        return (
            <StaticQuery query={MediaQuery} render={(data) =>
                <MainCover
                    media={{
                        url: undefined,
                        placeholder: data.imageSharp?.fluid?.srcWebp,
                        styles: {
                            backgroundColor: darkModeFill,
                            transform: 'scale(1)',
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            objectPosition: 'right center'
                        }
                    }}
                    styles={{
                        ...this.props.style
                    }}
                >
                    <NavigationHeader/>
                    {this.props.children}
                </MainCover>
            }/>
        )
    }
};

const StateWrapper = component => props => {
    const colorMode = useColorMode();
    const isDark = colorMode[0] === 'dark';
    const fill = isDark ? "#fff" : "#000";

    return <MainCoverImpl {...props} fill={fill}/>
};

export default StateWrapper(MainCoverImpl);
